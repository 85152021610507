import DelayIcon from "../icons/delay_icon"
import FilterIcon from "../icons/filter_icon"

import colors from "foundation/_colors_export.scss?variables"

interface Props {
  brandColor: string
  image?: string
  name: string
  serviceName: string
  serviceModuleName?: string
  stepUrl?: string
  label: string
  finalStep?: boolean
  description?: string
}

const StepDetails = ({
  brandColor,
  image,
  name,
  serviceName,
  serviceModuleName,
  stepUrl,
  label,
  finalStep,
  description,
}: Props) => {
  const isFilter = label === "When"
  const isDelay = label === "Wait"
  const largeImage = label === "If" || label === "Then"
  const imgStyle = largeImage ? "large" : "small"
  const stepDetailCopy = description ? description : name

  const serviceImage = (imgStyle: string) => {
    const size: string = imgStyle === "large" ? "32" : "18"

    if (isFilter) {
      return <FilterIcon title={serviceName} />
    } else if (isDelay) {
      return <DelayIcon title={serviceName} />
    } else {
      if (serviceModuleName) {
        return (
          <a href={`/${serviceModuleName}`} title={serviceName}>
            <img src={image} width={size} height={size} alt={`${serviceName} icon`} loading="lazy" />
          </a>
        )
      } else {
        return <img src={image} width={size} height={size} alt={`${serviceName} icon`} loading="lazy" />
      }
    }
  }

  const stepDetail = (name: string, stepDetailCopy: string) => {
    return (
      <>
        <h4 className="service-name">{name}</h4>
        {isFilter || isDelay ? <></> : <p className="txt-caption">{stepDetailCopy}</p>}
      </>
    )
  }

  return (
    <>
      <div className={finalStep ? "step final" : "step"}>
        <div className="details-row">
          <h3 className="label">{label}</h3>
          <div className="icon">
            <div className={imgStyle} style={{ backgroundColor: brandColor }}>
              {serviceImage(imgStyle)}
            </div>
          </div>
          <div className="details">
            {stepUrl ? (
              <a
                href={stepUrl}
                title={`Learn more: ${serviceName} - ${name}`}
                style={{ color: colors.primaryTextColor }}
              >
                {stepDetail(name, stepDetailCopy)}
              </a>
            ) : (
              stepDetail(name, stepDetailCopy)
            )}
          </div>
        </div>
      </div>
      {finalStep ? null : (
        <div className="guideline-container">
          <div className="label" />
          <div className="guideline" />
        </div>
      )}
    </>
  )
}

export default StepDetails
