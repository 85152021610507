import { Component } from "react"
import PropTypes from "prop-types"
import GrantAccessButton from "./grant_access_button"

export default class GrantAccess extends Component {
  handleClick = () => {
    this.props.onAccessGranted(this.props.mainService.module_name)
  }

  componentDidMount() {
    // If all services are connected, go to next step.
    this.props.requirementsMet() && this.props.executeConnectionFlow()
  }

  render() {
    return (
      <>
        <GrantAccessButton
          mainService={this.props.mainService}
          services={this.props.services}
          lastServiceConnected={this.props.lastServiceConnected}
          onConnectClick={this.handleClick}
          connected={this.props.appletConnected}
          authenticate={true}
          autoClick={true}
          dynamicConfig={this.props.dynamicConfig}
          showKnob={false}
          ctaCopy={`Going to ${this.props.mainService.short_name}`}
          animation={this.props.animation}
          updateAnimationCol={this.props.updateAnimationCol}
          requirementsMet={this.props.requirementsMet}
          appletIsSaving={this.props.appletIsSaving}
          connectButtonMaxFontSize={this.props.connectButtonMaxFontSize}
        />
      </>
    )
  }
}

GrantAccess.propTypes = {
  dynamicConfig: PropTypes.bool,
  mainService: PropTypes.object.isRequired,
  appletConnected: PropTypes.bool,
  services: PropTypes.array.isRequired,
  onAccessGranted: PropTypes.func.isRequired,
  lastServiceConnected: PropTypes.object,
  animation: PropTypes.object.isRequired,
  updateAnimationCol: PropTypes.func.isRequired,
  requirementsMet: PropTypes.func,
  appletIsSaving: PropTypes.bool,
  executeConnectionFlow: PropTypes.func.isRequired,
  connectButtonMaxFontSize: PropTypes.number,
}
